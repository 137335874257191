import { createRoot } from "react-dom/client"
import { v4 as uuid } from "uuid"

// normalize CSS across browsers
import "./src/normalize.scss"
// custom CSS styles
import "./src/style.scss"

export const replaceHydrateFunction = () => {
  uuid()
  return (element, container) => {
    const root = createRoot(container)
    root.render(element)
  }
}

/*pianoAnalytics.setConfigurations({
  site: siteId,
  collectDomain: "https://nssvsmp.pa-cd.com",
})
pianoAnalytics.setVisitorId(clientId)
pianoAnalytics.sendEvent("page.display", {
  page_chapter1: "Portail Innovation",
  page: "index",
})*/
